// Navigation Bar SECTION
import { FaGithub, FaFacebook, FaTwitter } from "react-icons/fa";
const navBar = {
  show: true,
};

// Main Body SECTION
const mainBody = {
  gradientColors: "#3171d5, #3d82ed, #4f90f5, #2073f3, #0c60e2, #2173f1",
  firstName: "Ralph",
  middleName: "",
  lastName: "De Lara",
  message: " I love the continuous learning and growth opportunities that come with being a software developer. ",
  icons: [
    {
      image: <FaGithub className="socialicons" size={56} />,
      url: "https://github.com/ralphdelara",
    },
    {
      image: <FaFacebook className="socialicons" size={56} />,
      url: "https://www.facebook.com/ralph.r.delara",
    },
    {
      image: <FaTwitter className="socialicons" size={56} />,
      url: "https://www.twitter.com/ralph_delara/",
    },
  ],
};

// ABOUT SECTION
// If you want the About Section to show a profile picture you can fill the profilePictureLink either with:
//a) your Instagram username
//      i.e:profilePictureLink:"johnDoe123",
//b) a link to an hosted image
//      i.e:profilePictureLink:"www.picturesonline.com/johnDoeFancyAvatar.jpg",
//c) image in "editable-stuff" directory and use require("") to import here,
//      i.e: profilePictureLink: require("../editable-stuff/ralphdelara.jpg"),
//d) If you do not want any picture to be displayed, just leave it empty :)
//      i.e: profilePictureLink: "",
// For Resume either provide link to your resume or import from "editable-stuff" directory
//     i.e resume: require("../editable-stuff/resume.pdf"),
//         resume: "https://docs.google.com/document/d/13_PWdhThMr6roxb-UFiJj4YAFOj8e_bv3Vx9UHQdyBQ/edit?usp=sharing",

const about = {
  show: true,
  heading: "About Me",
  imageLink: require("../editable-stuff/ralphdelara.jpg"),
  imageSize: 375,
  message:
    "My name is Ralph De Lara. I’m a graduate of batch 2013 from Baliuag University with a degree in Computer Engineering. "
    + "I like everything about computer, and my goal is to continuously learn and improve my skills in software engineering. "
    + "In my free time I like watching tutorial videos, searching something that popped up on my head, working on my portfolio project and playing games.",
  resume: "",
};

// PROJECTS SECTION
// Setting up project lenght will automatically fetch your that number of recently updated projects, or you can set this field 0 to show none.
//      i.e: reposLength: 0,
// If you want to display specfic projects, add the repository names,
//      i.e ["repository-1", "repo-2"]
const repos = {
  show: true,
  heading: "Recent Projects",
  gitHubUsername: "ralphdelara", //i.e."johnDoe12Gh"
  reposLength: 4,
  specificRepos: [],
};

const projects = {
  show: true,
  heading: "Projects",
  projectsList: [
    {
      img: '',
      name: 'Positively',
      description: 'Positively is cloud-based platform ensures that business owners can access real-time data from anywhere, making it an ideal solution for businesses with multiple locations.',
      tools: ['React.js', 'Laravel', 'MySQL'],
      dateStarted: '2023-03-01',
      url: 'https://positively.ralphdelara.com'
    },
    {
      img: '',
      name: 'WaterWorks',
      description: 'WaterWorks is a digital platform that helps utilities manage water billing operations efficiently and effectively, reduce errors and inaccuracies, and improve customer satisfaction.',
      tools: ['React.js', 'Laravel', 'MySQL'],
      dateStarted: '2023-03-01',
      url: 'https://waterworks.ralphdelara.com'
    },
  ]
};

// Leadership SECTION
const leadership = {
  show: false,
  heading: "Leadership",
  message:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Vitae auctor eu augue ut lectus arcu bibendum at varius. Libero justo laoreet sit amet cursus sit amet. Imperdiet dui accumsan sit amet nulla facilisi morbi. At auctor urna nunc id. Iaculis urna id volutpat lacus laoreet non curabitur gravida. Et magnis dis parturient montes nascetur ridiculus mus mauris. In nisl nisi scelerisque eu ultrices vitae auctor. Mattis nunc sed blandit libero volutpat sed cras ornare. Pulvinar neque laoreet suspendisse interdum consectetur libero.",
  images: [
    { 
      img: require("../editable-stuff/ralphdelara.jpg"), 
      label: "First slide label", 
      paragraph: "Nulla vitae elit libero, a pharetra augue mollis interdum." 
    },
    { 
      img: require("../editable-stuff/ralphdelara.jpg"), 
      label: "Second slide label", 
      paragraph: "Nulla vitae elit libero, a pharetra augue mollis interdum." 
    },
  ],
  imageSize: {
    width:"615",
    height:"450"
  }
};

// SKILLS SECTION
const skills = {
  show: true,
  heading: "Skills",
  hardSkills: [
    { name: "HTML/CSS", value: 90 },
    { name: "PHP", value: 90 },
    { name: "Laravel", value: 80 },
    { name: "Craft CMS", value: 65 },
    { name: "SQL", value: 75 },
    { name: "JavaScript", value: 90 },
    { name: "React", value: 75 },
    { name: "Node.js", value: 65 },
  ],
  softSkills: [
    { name: "Communication", value: 90 },
    { name: "Time management", value: 70 },
    { name: "Creativity", value: 75 },
    { name: "Adaptability", value: 85 },
    { name: "Problem Solving", value: 85 },
    { name: "Teamwork", value: 70 },
    { name: "Emotional intelligence", value: 70 },
    { name: "Leadership", value: 65 },
  ],
};

// GET IN TOUCH SECTION
const getInTouch = {
  show: false,
  heading: "Get In Touch",
  message:
    "I'm currently looking for full-time Software Engineering or Machine Learning opportunities! If you know of any positions available, if you have any questions, or if you just want to say hi, please feel free to email me at",
  email: "ralphrdelara@gmail.com",
};

const experiences = {
  show: true,
  heading: "Experience",
  data: [
    {
      role: 'Software Engineer',// Here Add Company Name
      roleDescription: 'Responsible for designing and developing customized software solutions for clients.',
      companylogo: require('../assets/img/imeus.png'),
      date: 'August 2013 – December 2020',
      companyDescription: 'a Malaysian-based software development company that specializes in developing customized software solutions for businesses and healthcare.'
    },
    {
      role: 'Software Engineer',
      roleDescription: 'Work closely with other software engineers, product managers, and business analysts to identify client requirements, develop software solutions, and ensure that they meet the required standards for functionality, security, and performance.',
      companylogo: require('../assets/img/vaultech.png'),
      date: 'January 2021 - Present',
      companyDescription: 'A company based on UK that specializes in developing customized web and mobile applications for financial industries',
    },
  ]
}

// Blog SECTION
// const blog = {
//   show: false,
// };

export { navBar, mainBody, about, repos, skills, leadership, getInTouch, experiences, projects };
