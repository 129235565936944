import React from 'react';

import {
  Col,
} from "react-bootstrap";

const ExperienceCard = ({ data }) => {
  return (
    <Col lg="6">
      <div className="pb-5 text-center">
        <img className="bg-white mb-3" src={data.companylogo} alt="" />
        <p className="lead mb-3">
          <h3>{data.date}</h3>
          {data.companyDescription}
        </p>
        <p className="lead">
          <h3>{data.role}</h3>
          {data.roleDescription}
        </p>
      </div>
    </Col>
  );
}

export default ExperienceCard;