import React from "react";
import Container from "react-bootstrap/Container";
import { FaCode, FaHeart, FaReact } from "react-icons/fa";

const Footer = (props) => {
  const bgStyle = { backgroundColor: "#f5f5f5" };

  return (
    <footer style={bgStyle} className="mt-auto py-5 text-center ">
      <Container>
        {props.children}
        <FaCode /> with <FaHeart /> by{" "}
        <a
          rel="noopener"
          href="https://github.com/ralphdelara"
          aria-label="My GitHub"
        > <span className="badge bg-dark">
            Ralph De Lara
          </span>
        </a>{" "}
        using <FaReact />
      </Container>
    </footer>
  );
};

export default Footer;
